import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Logo from "@/src/images/2020/img_logo_horizontal.svg"
// ______________________________________________________
//
type Props = {
  className?: string
  title: string
  text: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <p className="logo">
      <Logo />
    </p>
    <div className="message">
      <h2 className="title">{props.title}</h2>
      <p className="text">{props.text}</p>
    </div>
  </div>
)
// ______________________________________________________
//
const pcStyle = `
> .logo {
  svg {
    width: 180px;
    height: 180px;
  }
}
> .message {
  margin-left: 50px;
  > .title {
    font-size: 4.8rem;
  }
}
`
const spStyle = `
flex-direction: column;
> .logo {
  svg {
    width: 160px;
    height: 160px;
  }
}
> .message {
  text-align: center;
  > .title {
    font-size: 3.2rem;
  }
}
`
const StyledComponent = styled(Component)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > .message {
    max-width: 480px;
    > .title {
      font-family: "Roboto", sans-serif;
    }
  }
  background: linear-gradient(
    180deg,
    rgba(31, 28, 180, 0) 0%,
    rgba(31, 28, 180, 0.35) 100%
  );
  ${media.lessThan("medium")` ${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
